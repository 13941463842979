import React, { useState } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { DownOutlined } from "@ant-design/icons";

const RecordPerPage = (props) => {
	const [Value, SetValue] = useState(props.defaultValue)

	const DropdownItems = [
		{ value: 500, text: '500 per page' },
		{ value: 1000, text: '1000 per page' },
		{ value: 10000, text: '10000 per page' },
	]

	const HandleMenuClick = (PageSize, PageText) => {
		SetValue(PageText)
		props.onChange(PageSize)
	}

	const MenuElement = (
		<Menu>
			{DropdownItems.map((item, index) => (
				<Menu.Item key={index} onClick={() => HandleMenuClick(item.value, item.text)}>{item.text}</Menu.Item>
			))}
		</Menu>
	)

	return (
		<Dropdown overlay={MenuElement}>
			<Button>
				{Value}
				<DownOutlined />
			</Button>
		</Dropdown>
	)
}

export default RecordPerPage
