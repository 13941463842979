import React from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import useStore from '../../store'

const AppsBreadcrumbComponent = (props) => {
    const { AppsAuthStore: { apps_ledger_base_currency } } = useStore()
    return (
        <Breadcrumb>
            {(props.items) ? props.items.map((item, index) => (
                <Breadcrumb.Item key={index}>
                    {
                        (item.link && apps_ledger_base_currency) ? (
                            <Link to={item.link}>{item.name}</Link>
                        ) : (item.name)
                    }
                </Breadcrumb.Item>
            )) : null}
        </Breadcrumb>
    )
}

export default AppsBreadcrumbComponent
